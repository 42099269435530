import React from 'react';
import './footer.scss';
import Nav from 'react-bootstrap/Nav';
import logo from '../../images/logo.png';
import insta from '../../images/instagram-sc.png';
import facebook from '../../images/facebook-sc.png';
import linkedIn from '../../images/linkedIn-sc.png';
import twitter from '../../images/twitter-sc.png';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

function Footer() {
	return (<div className='footer-component'>
		<div className='container footer-banner'>
			<div className='footer-content'>
				<h2>Ready to start?</h2>
				<p className='description'>Molestie nunc ut egestas faucibus orci porta ultrices proin a. Lectus amet sed aliquam cursus risus. Feugiat aliquam diam adipiscing cursus. In tristique dictum facilisis id.</p>
				<form>
					<Form.Control size="lg" type="text" placeholder="Name" />
					<Form.Control size="lg" type="text" placeholder="Email" />
					<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
						<Form.Label>Example textarea</Form.Label>
						<Form.Control as="textarea" rows={3} />
					</Form.Group>
					<Button variant="primary">Submit</Button>
				</form>
			</div>
		</div>
		<div className='container footer-top'>
			<div className='logo'>
				<img src={logo} alt="logo"></img>
			</div>
			<div className='footer-nav'>
				<Nav className="me-auto">
						<Nav.Link href="#home">Industries</Nav.Link>
						<Nav.Link href="#link">About Us</Nav.Link>
				</Nav>
			</div>
			<div className='social-media hide-desktop'>
				<div className='sc-icon'>
					<img src={insta} alt="instagram"></img>
				</div>
				<div className='sc-icon'>
					<img src={facebook} alt="facebook"></img>
				</div>
				<div className='sc-icon'>
					<img src={twitter} alt="twitter"></img>
				</div>
				<div className='sc-icon'>
					<img src={linkedIn} alt="linkedIn"></img>
				</div>
			</div>
		</div>
		<div className='container footer-bottom'>
			<div className='copywrite'>© 2024. All rights reserved.</div>
			<div className='social-media show-mobile'>
				<div className='sc-icon'>
					<img src={insta} alt="instagram"></img>
				</div>
				<div className='sc-icon'>
					<img src={facebook} alt="facebook"></img>
				</div>
				<div className='sc-icon'>
					<img src={twitter} alt="twitter"></img>
				</div>
				<div className='sc-icon'>
					<img src={linkedIn} alt="linkedIn"></img>
				</div>
			</div>
		</div>
	</div>);
}

export default Footer;
