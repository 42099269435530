import { Outlet, Route, Routes } from "react-router-dom";
import "./App.scss";
import Footer from "./components/footer";
import HomePage from './pages/home';
import AboutUs from "./pages/about";
import Industries from "./pages/industries";

function Layout(props) {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="industries" element={<Industries />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
