import React from "react";
import styles from "./our-services.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "../card";

import service1 from "../../images/service1.png";
import service2 from "../../images/service2.png";
import service3 from "../../images/service3.png";
import service4 from "../../images/service4.png";

const CARD_COMPONENT = [
  {
    id: 1,
    image: service1,
    title: "Expertise As A Service",
    subTitle: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.",
    socialIcon: "",
  },
  {
    id: 2,
    image: service2,
    title: "Expertise As A Service",
    subTitle: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.",
    socialIcon: "",
  },
  {
    id: 3,
    image: service3,
    title: "Expertise As A Service",
    subTitle: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.",
    socialIcon: "",
  },
  {
    id: 4,
    image: service4,
    title: "Expertise As A Service",
    subTitle: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.",
    socialIcon: "",
  },
];

const OurServices = ({ data }) => {
  return (
    <>
      {
        <div className="ourServices-container container">
          <p className="subtitle">UNSER GRUNDWERT</p>
          <h2>Lorem ipsum dolor sit</h2>
          <Row>
            {CARD_COMPONENT.map((obj) => (
              <Col sm={6} key={obj.id}>
                {" "}
                <Card data={obj}></Card>
              </Col>
            ))}
          </Row>
        </div>
      }
    </>
  );
};

export default OurServices;
