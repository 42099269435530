import React from "react";
import styles from "./testimonials.scss";
import Carousel from 'react-bootstrap/Carousel';
import kapilimg from "../../images/kapil_img.png";
import frankimg from "../../images/frank_img.png";
import michaelimg from "../../images/michael_img.png";
import bg from "../../images/testimonials-bg.png"
import qoute from "../../images/qoute.png"
const Testimonials = [
  {
    id: 1,
    image: kapilimg,
    title: "Kapil Gupta",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
  },
  {
    id: 2,
    image: frankimg,
    title: "Frank Bieser",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
  },
  {
    id: 3,
    image: michaelimg,
    title: "Michael Schäfer",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
  },
];

const testimonials = ({ data }) => {
  return (
    <>
      {
        <div className="testimonials-container container">
          <p className="subtitle">Testimonials</p>
          <h2>Testimonials</h2>
          <Carousel fade >
              	{Testimonials.map((obj) => <Carousel.Item  interval={1000} className='content' key={obj.id} > 
                <div className='img-wrapper'>
                  <img src={obj.image} alt="card"></img>
                </div>
                {/* <img
                  className="d-block w-100"
                  src={bg}
                  alt="First slide"
                /> */}
                {/* <div className='img-wrapper'>
                  <img src={obj.image} alt="card"></img>
                </div> */}
                  <Carousel.Caption>
                  <h3>{obj.title}</h3>
                  <p className='description'>{obj.description}</p>
                  </Carousel.Caption>
                  <p className="quote">“</p>
                </Carousel.Item >)}
        </Carousel>
        </div>
      }
    </>
  );
};

export default testimonials;
