import React, { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../images/logo.png";
import "./navbar.scss";


const AppNavbar = () => {
    
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
        window.removeEventListener('scroll', isSticky);
      };
  });

        
/* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
      const header = document.querySelector('.header-section');
      const scrollTop = window.scrollY;
      scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
  };
  return (
    <header className="header-section">
      <Navbar expand="lg" className="navComponent container">
        <Navbar.Brand href="/">
          <div className="logo">
            <img src={logo} alt="logo"></img>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/industries">Industries</Nav.Link>
            <Nav.Link href="/about">About Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default AppNavbar;
