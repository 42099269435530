import industriesimg1 from "../../images/industries_img1.jpg";
import industriesimg2 from "../../images/industries_img2.jpg";
import industriesimg3 from "../../images/industries_img3.jpg";
import industriesimg4 from "../../images/industries_img4.jpg";
import heroImg from "../../images/industries_hero_desk.jpg"

export const HERO = [
  {
    id: 1,
    image: heroImg,
    title: "We live and breathe tech.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
    button: ''
  },
];


export const ABOUT_US1 = [
  {
    id: 1,
    image: industriesimg1,
    title: "ABOUT US",
    subTitle: "Hallo, wir sind, RespondGPT",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
  },
  {
    id: 2,
    image: industriesimg2,
    title: "ABOUT US",
    subTitle: "Hallo, wir sind, RespondGPT",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
  },
  {
    id: 3,
    image: industriesimg3,
    title: "ABOUT US",
    subTitle: "Hallo, wir sind, RespondGPT",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
  },
  {
    id: 4,
    image: industriesimg4,
    title: "ABOUT US",
    subTitle: "Hallo, wir sind, RespondGPT",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
  },
];

