import React from "react";
import styles from "./benifits.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "../card";

import benifits1 from "../../images/availibility.png";
import benifits2 from "../../images/response.png";
import benifits3 from "../../images/scalability.png";
import benifits4 from "../../images/cost.png";
import benifits5 from "../../images/consistency.png";
import benifits6 from "../../images/peronalization.png";

const CARD_COMPONENT = [
  {
    id: 1,
    image: benifits1,
    title: "24/7 Availability",
    subTitle: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.",
    socialIcon: "",
  },
  {
    id: 2,
    image: benifits2,
    title: "Instant Responses",
    subTitle: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.",
    socialIcon: "",
  },
  {
    id: 3,
    image: benifits3,
    title: "Scalability",
    subTitle: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.",
    socialIcon: "",
  },
  {
    id: 4,
    image: benifits4,
    title: "Cost-Effectiveness",
    subTitle: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.",
    socialIcon: "",
  },
  {
    id: 5,
    image: benifits5,
    title: "Consistency",
    subTitle: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.",
    socialIcon: "",
  },
  {
  id: 6,
  image: benifits6,
  title: "Personalization",
  subTitle: "",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam.",
  socialIcon: "",
},
];

const Benifits = ({ data }) => {
  return (
    <>
      {
        <div className="benifits-container container">
          <h2>Benefits</h2>
          <p className="description">There are many variations of passages of Lorem Ipsum available
but the majority have suffered alteration in some form.</p>
          <Row>
            {CARD_COMPONENT.map((obj) => (
              <Col sm={4} key={obj.id}>
                {" "}
                <Card data={obj}></Card>
              </Col>
            ))}
          </Row>
        </div>
      }
    </>
  );
};

export default Benifits;
