import About from "../../components/about";
import OurSites from "../../components/our-sites";
import Hero from "../../components/hero";
import Value from "../../components/value";
import { ABOUT_US1, ABOUT_US2, HERO } from "./constant";

function Industries() {
  return (
    <>
      <Hero data={HERO} />
      <About data={ABOUT_US1} />
      <Value></Value>
      <OurSites />
    </>
  );
}

export default Industries;
