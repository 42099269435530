import About from "../../components/about";
import OurServices from "../../components/our-services";
import OurSites from "../../components/our-sites";
import OurTeam from "../../components/our-team";
import Hero from "../../components/hero";
import Testimonials from "../../components/testimonials"
import Benifits from "../../components/benifits";
import Value from "../../components/value";
import { ABOUT_US1, ABOUT_US2, HERO } from "./constant";

function AboutUs() {
  return (
    <>
      <Hero data={HERO} />
      <About data={ABOUT_US1} />
      <OurTeam />
      <OurServices />
      <Benifits></Benifits>
      <Value></Value>
      <Testimonials />
      <About data={ABOUT_US2} />
      <OurSites />
    </>
  );
}

export default AboutUs;
