import React from "react";
import "./hero.scss";
import AppNavbar from "../navbar";

const Hero = ({ data }) => {
  return (
    <>
      {
        data.map((obj) => <div className="hero-banner" key={obj.id}>
          <div className="img-wrapper">
            <img src={obj.image} />
            <AppNavbar />
            <div className="container">
              <div className="hero-content">
                <h1>{obj.title}</h1>
                <p className="description">
                  {obj.description}
                </p>
              </div>
            </div>
          </div>
        </div>)
      }
    </>
  )
};

export default Hero;
