import React from 'react';
import styles from './card.scss';

const Card = ({ data }) => {
	return (
		<div className='card-container'>
			<div className='card-content'>
				<div className='img-wrapper'>
					<img src={data.image} alt="card"></img>
				</div>
				<h3>{data.title}
				</h3>
				<p className='subtitle'>{data.subTitle}</p>
				<p className='description'>{data.description}</p>
			</div>
		</div>
	)
};

export default Card;
