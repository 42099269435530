import React from 'react';
import './about.scss';

const About = ({ data }) => {
	return (
		<>
			{
				data.map((obj) => <div className='container aboutus-container' key={obj.id}>
					<div className='aboutus-content'>
						<div className='left-content'>
							<img src={obj.image} alt="aboutus"></img>
						</div>
						<div className='right-content'>
							<p className='subtitle'>{obj.title}</p>
							<h2>
							{obj.subTitle}
							</h2>
							<p className='description'>{obj.description}</p>
						</div>
					</div>
				</div>)
			}
		</>
	)
};

export default About;
