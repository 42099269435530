import React from "react";
import styles from "./value.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "../card";

import value1 from "../../images/availibility.png";
import value2 from "../../images/response.png";
import value3 from "../../images/scalability.png";
import value4 from "../../images/cost.png";
import value5 from "../../images/consistency.png";
import value6 from "../../images/peronalization.png";

const CARD_COMPONENT = [
  {
    id: 1,
    image: value1,
    title: "Innovation",
  },
  {
    id: 2,
    image: value2,
    title: "Customer-Centricity",
  },
  {
    id: 3,
    image: value3,
    title: "Excellence",
  },
  {
    id: 4,
    image: value4,
    title: "Trustworthinness",
  },
];

const Value = ({ data }) => {
  return (
    <>
      {
        <div className="value-container container">
          <h2>Our Core Values</h2>
          <p className="description">Embroce the Future and Emplower Relationships with RespondGPT </p>
          <Row>
            {CARD_COMPONENT.map((obj) => (
              <Col sm={3} key={obj.id}>
                {" "}
                <Card data={obj}></Card>
              </Col>
            ))}
          </Row>
        </div>
      }
    </>
  );
};

export default Value;
