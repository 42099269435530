import aboutusimg1 from "../../images/about_us_img1.jpg";
import aboutusimg2 from "../../images/about_us_img2.jpg";
import aboutusimg3 from "../../images/about_us_img3.jpg";
import heroImg from "../../images/about_hero_desk.jpg"

export const HERO = [
  {
    id: 1,
    image: heroImg,
    title: "We live and breathe tech.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
    button: ''
  },
];


export const ABOUT_US1 = [
  {
    id: 1,
    image: aboutusimg1,
    title: "ABOUT US",
    subTitle: "Hallo, wir sind, RespondGPT",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
  },
  {
    id: 2,
    image: aboutusimg2,
    title: "ABOUT US",
    subTitle: "Hallo, wir sind, RespondGPT",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
  },
];

export const ABOUT_US2 = [
  {
    id: 1,
    image: aboutusimg3,
    title: "ABOUT US",
    subTitle: "Hallo, wir sind, RespondGPT",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
  },
];
