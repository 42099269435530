import React from 'react';
import styles from './our-sites.scss';
import map from '../../images/Map.png';

const OURSITES_COMPONENT = [{
	id: 1,
	title: 'Frankfurt am Main, Deutschland',
	address1: 'Taunustor 1,',
	address2: '60310 Frankurt am Main',
	email: 'info@zirkeltech.com',
  }, {
	id: 2,
	title: 'München, Deutschland',
	address1: 'Ludwigstraße 8, Ludwigpalais,',
	address2: '80539 München',
	email: 'info@zirkeltech.com',
  },
  {
	id: 3,
	title: 'London, UK',
	address1: 'Taunustor 1,',
	address2: '60310 Frankurt am Main',
	email: 'info@zirkeltech.com',
  },
  {
	id: 4,
	title: 'New York, USA',
	address1: '244 Fifth Avenue,',
	address2: 'Suite K295, New York, NY 10001',
	email: 'info@zirkeltech.com',
  },
  {
	id: 5,
	title: 'Toronto, Kanada',
	address1: '231 Oak Park Blvd., Suite 301,',
	address2: 'Oakville, ON L6H 7S8',
	email: 'info@zirkeltech.com',
  },
  {
	id: 6,
	title: 'Wien, Österreich',
	address1: 'Tuchlauben 7a,',
	address2: 'A-1010 Wien',
	email: 'info@zirkeltech.com',
  }
  ];

const OurSites = ({ data }) => {
	return (
		<>
			{

				<div className='ourSites-container container'>
					<p className='subtitle'>UNSERE STANDORTE</p>
					<h2>Erleben Sie mit uns spannende Einblicke!</h2>
					<div className='img-wrapper'>
						<img src={map} alt="Map"></img>
					</div>
					<div className='address-wrapper'>
						{OURSITES_COMPONENT.map((obj) => <div className='content' sm key={obj.id} > 
							<h3>{obj.title}</h3>
							<p className='description'>{obj.address1}</p>
							<p className='description'>{obj.address2}</p>
							<p className='description email'>{obj.email}</p>
						</div>)}
					</div>
			  	</div>

			}
		</>
	)
};

export default OurSites;
