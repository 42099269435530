import React from "react";
import styles from "./our-team.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "../card";

import kapilimg from "../../images/kapil_img.png";
import frankimg from "../../images/frank_img.png";
import michaelimg from "../../images/michael_img.png";
import linkedin from "../../images/Linkedin.png";
import xing from "../../images/Xing.png";

const CARD_COMPONENT = [
  {
    id: 1,
    image: kapilimg,
    title: "Kapil Gupta",
    subTitle: "Global Delivery",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
    socialIcon: {
      img1: linkedin,
      img2: xing,
    },
  },
  {
    id: 2,
    image: frankimg,
    title: "Frank Bieser",
    subTitle: "Agile & Digital Transformation Coach",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
    socialIcon: {
      img1: linkedin,
    },
  },
  {
    id: 3,
    image: michaelimg,
    title: "Michael Schäfer",
    subTitle: "Enterprise Program Management",
    description:
      "Das Verständnis der Vision unseres Kunden, seiner geschäftlichen Triebkräfte und Bereiche mit hoher Wirkung sowie der Kernhypothese des Produkts ermöglicht es uns, den Ansatz vorzuschlagen, der unseren Kunden hilft, den ROI zu maximieren.",
    socialIcon: {
      img1: linkedin,
    },
  },
];

const OurTeam = ({ data }) => {
  return (
    <>
      {
        <div className="ourTeam-container container">
          <p className="subtitle">Our Team</p>
          <h2>Wir sind Ihr vertrauenswürdiger Partner</h2>
          <Row>
            {CARD_COMPONENT.map((obj) => (
              <Col sm key={obj.id}>
                {" "}
                <Card data={obj}></Card>
              </Col>
            ))}
          </Row>
        </div>
      }
    </>
  );
};

export default OurTeam;
